import React from 'react'



function Error() {

    return (
        <main>
           
        </main>
    )
}

export default Error;
